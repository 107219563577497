import React from "react";

export const Image = ({ title, largeImage, smallImage,  addedClass}) => {
  const classes = `img-responsive ${!!addedClass ? addedClass : ""}`
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className={classes} alt={title} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};

import React from 'react';

export const Pricing = (props) => {
  if (!props.data) return "Loading...";

  //const colWidth = 4;

  return (
    <section id="pricing" className="pricing py-5 text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Pricing</h2>
        </div>
        <div className="row">
          {props.data.items.map( opt => (
            <div className="col-lg-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase">{opt.name}</h5>
                  <h6 className="card-price">{opt.price} <span className="period">{opt.priceDetails}</span></h6>
                  <hr/>
                  <ul className="list-group">
                    {opt.features.map(f => (
                        <li className="list-group-item"><i className="fa fa-solid fa-square-check"></i>{f}</li>
                    ))}
                  </ul>
                 </div>
              </div>
            </div>
          ))} 
        </div>
        <span>Discounts available for extended durations. Pickup & dropoff available for additional fees. All rentals subject to availability.</span>
      </div>
    </section>
  );
};
